<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="customerAddressId != null">Edit Customer Address</b>
      <b v-else>Create Customer Address</b>
    </span>
    <vs-tabs>
      <vs-tab label="Address">
        <div class="tab-text">
          <div class="vx-row mb-6" v-if="showButtonSubmit">
            <div class="vx-col w-full">
              <label class="vs-input--label">Select Customer</label>
              <multiselect
                v-model="data.customer.selected"
                :options="data.customer.options"
                @search-change="getDataCustomer"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="150"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-1/2">
              <vs-input v-if="data.customerCodeExternal == ''" class="w-full readonly" label="Code Customer" v-model="data.customerCode" name="Code" readonly/>
              <vs-input v-else class="w-full readonly" label="Customer Code" v-model="data.customerCode" name="Code" readonly/>
            </div>
            <div class="vx-col w-1/2">
              <vs-input class="w-full readonly" label="Customer Name" v-model="data.customerName" name="Name" readonly/>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-1/2">
              <vs-input class="w-full readonly" label="Code" v-model="data.code" name="Code" readonly/>
            </div>
            <div class="vx-col w-1/2">
              <vs-input class="w-full readonly" label="Code NOO" v-model="data.nooCode" name="Name" readonly/>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Status</label>
              <vs-checkbox v-model="data.status" vs-value="Billing Address" disabled
                >Billing Address</vs-checkbox
              >
              <vs-checkbox v-model="data.status" vs-value="Shipping Address" disabled
                >Shipping Address</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Territory</label>
              <multiselect
                v-model="territory.selected"
                :options="territory.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
              <span
                class="text-danger text-sm"
                v-show="errors.has('TerritoryCustomer')"
                >{{ errors.first("TerritoryCustomer") }}</span
              >
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Sales</label>
              <multiselect
                v-model="salesPersonal.selected"
                :options="salesPersonal.options"
                placeholder="Type to search"
                track-by="Name"
                label="Name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.Name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.Name }}</span>
                  </div>
                </template>
              </multiselect>
              <span
                class="text-danger text-sm"
                v-show="errors.has('SalesCustomer')"
                >{{ errors.first("SalesCustomer") }}</span
              >
            </div>
          </div>

          <address-component
            :data="data.address"
            @data="setDataAddress"
          ></address-component>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
              class="w-full"
              label="RT / RW (Format 000/000)"
              v-model="data.rtRw"
              @keypress="isNumber($event)"
              name="RT/RW"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('RT/RW')"
              >{{ errors.first("RT/RW") }}</span
            >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                label="Street No"
                v-model="data.streetNo"
              />
            </div>
          </div>
        </div>
        
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Collector</label>
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="data.isTaxCollector"
              disabled
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Exempt</label>
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="data.isTaxExempt"
              disabled
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Type</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <multiselect
                  v-model="taxType.selected"
                  :options="taxType.options"
                  placeholder="Type to search"
                  track-by="name"
                  label="name"
                  :max-height="125"
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                      >
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                      >
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Status Block</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <multiselect
                  v-model="StatusBlock"
                  :options="StatusBlockOption"
                  placeholder="Type to search"
                  track-by="code"
                  label="code"
                  :max-height="125"
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <label class="vs-input--label">Notes</label>
            <vs-textarea class="w-full" v-model="data.notes" />
          </div>
        </div>
        <vs-divider style="width: 100%; margin-left: 0%">History Notes</vs-divider>
        <vs-table
          stripe
          border
          description
          :sst="true"
          :data="data.historyNotes"
        >
          <template slot="thead">
            <vs-th>Time</vs-th>
            <vs-th>User</vs-th>
            <vs-th>Notes</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">{{ formatDateTime(tr.CreatedAt) }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ tr.personal_name }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ tr.notes }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-tab>
      <vs-tab label="Map">
        <div class="tab-text">
          <map-component :data="data.map" @data="setDataMap"></map-component>
        </div>
      </vs-tab>
      <vs-tab label="Contact">
        <div class="tab-text">
          <contact-component
            :data="data.contact"
            @data="setDataContact"
          ></contact-component>
        </div>
      </vs-tab>
    </vs-tabs>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button v-if="statusApproval == '0' || showButtonSubmit || statusApproval == '4'" class="mr-3 mb-2" @click="handleSubmit">Submit & Send for Approval</vs-button>
        <vs-button v-else-if="statusApproval == '2' || showButtonSubmit || statusApproval == '2'" class="mr-3 mb-2" @click="handleSubmit">Update</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import ContactComponent from "./form-component/Contact";
import MapComponent from "../customer/form-component/Map.vue";
import AddressComponent from "@/components/master/Address";
import moment from "moment";
// import ReceiveOperationComponent from "./form-component/ReceiveOperation";
// import OperationComponent from "./form-component/Operation";

export default {
  components: {
    ContactComponent,
    MapComponent,
    AddressComponent,
    // ReceiveOperationComponent,
    // OperationComponent,
  },
  props: {
    customerId: {
      type: Number,
    },
    customerAddressId: {
      type: Number,
    },
    statusApproval: {
      type: String,
    },
    showButtonSubmit: {
      type: Boolean,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/oms/v1/sfa-master/customer-address",
        data: {
          historyNotes: [],
          notes: "",
          rtRw: "",
          street: "",
          streetNo: "",
          customerId: null,
          status: ["Shipping Address"],
          code: "",
          customerName: "",
          isTaxCollector: true,
          isTaxExempt: true,
          address: {
            countryName: "",
            countryID: 0,
            provinceName: "",
            provinceID: 0,
            cityName: "",
            cityID: 0,
            districtName: "",
            districtID: 0,
            subdistrictName: "",
            subdistrictID: 0,
            address: "",
            postalCode: "",
          },
          map: {
            latitude: "",
            longitude: "",
          },
          contact: {
            name: "",
            mobile: "",
            phone: "",
            email: "",
            fax: "",
            showWarningMobile: false,
            showWarningPhone: false
          },
          customer: {
            options: [],
            selected: null,
          },
        },
        taxType: {
          selected: null,
          options: [],
        },
        territory: {
          options: [],
          selected: null,
        },
        salesPersonal: {
          selected: null,
          options: [],
        },
        StatusBlock: null,
        StatusBlockOption: [
          { id: 0, code: "0 - Un-Block" },
          { id: 1, code: "1 - Order Block" },
          { id: 2, code: "2 - Return Block" },
          { id: 3, code: "3 - All Block" }
        ],
      };
    },
    formatDateTime(s){
      const m = moment.parseZone(s);
      return m.format('YYYY-MM-DD HH:mm:ss')
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.territory.selected) {
          this.errors.add({
            field: "TerritoryCustomer",
            msg: "The territory field is required",
          });
          result = false;
        }
        if (!this.salesPersonal.selected) {
          this.errors.add({
            field: "SalesCustomer",
            msg: "The Sales field is required",
          });
          result = false;
        }
        if (this.data.rtRw.length != 7 || this.data.rtRw.indexOf("/") != 3 || (this.data.rtRw.split("/").length - 1) > 1) {
          this.errors.add({
            field: "RT/RW",
            msg: "The RT / RW not valid format.",
          });
          result = false;
        }
        
        if (this.data.contact.mobile.length > 13) {
          this.data.contact.showWarningMobile = true
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field may not be greater than 13 characters.",
          });
          result = false;
        }
        if (this.data.contact.mobile.length < 10) {
          this.data.contact.showWarningMobile = true
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field at least 10 characters.",
          });
          result = false;
        }
        if (this.data.contact.phone.length > 13) {
          this.data.contact.showWarningPhone = true
          this.errors.add({
            field: "Phone",
            msg: "The Phone field may not be greater than 13 characters.",
          });
          result = false;
        }
        if (this.data.contact.phone.length < 10) {
          this.data.contact.showWarningPhone = true
          this.errors.add({
            field: "Phone",
            msg: "The Phone field at least 10 characters.",
          });
          result = false;
        }
        if (!this.taxType.selected) {
          this.notify("danger", "Form Validation", "The Tax Type field is required")
          result = false;
        }
        if (!this.StatusBlock) {
          this.notify("danger", "Form Validation", "The Status Block field is required")
          result = false;
        }

        if (result) {
          // this.$vs.loading();
          if (this.customerAddressId) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      let is_tax_collector = "0";
      if (this.data.isTaxCollector) {
        is_tax_collector = "1";
      }
      let is_tax_exempt = "0";
      if (this.data.isTaxExempt) {
        is_tax_exempt = "1";
      }

      return {
        notes: this.data.notes,
        customer_id: this.data.customerId,
        code: this.data.code,
        territory_id: this.territory.selected.id ? this.territory.selected.id : this.territory.selected.ID,
        sales_personal_id: this.salesPersonal.selected.ID ? this.salesPersonal.selected.ID : this.salesPersonal.selected.id,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        status: JSON.stringify(this.data.status),
        rt_rw: this.data.rtRw,
        street_no: this.data.streetNo,

        latitude: this.data.map.latitude,
        longitude: this.data.map.longitude,

        contact_name: this.data.contact.name,
        contact_mobile: this.data.contact.mobile,
        phone: this.data.contact.phone,
        email: this.data.contact.email,
        fax: this.data.contact.fax,

        is_tax_collector: is_tax_collector,
        is_tax_exempt: is_tax_exempt,
        tax_type_id: this.taxType.selected ? (this.taxType.selected ? this.taxType.selected.ID : this.taxType.selected.id) : 0,
        shiping_block: this.StatusBlock.id,
        status_approval: this.statusApproval,
      };
    },
    postData() {
      console.log("PARAMDATA",this.paramData())
      this.$http
        .post("/api/oms/v1/sfa-master/customer-address-sfa", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.notify("success", "Success", "New Customer Address Created")
          } else {
            this.notify("danger", "Error", resp.message)
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    generateApproval() {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "-approval/" + this.customerAddressId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.notify("success", "Success", "Customer Updated")
            this.$vs.loading.close();
          } else {
            this.notify("danger", "Error", resp.message)
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    notify(color, title, message){
      this.$vs.notify({
        color: color,
        title: title,
        text: message,
        position: "top-right",
        iconPack: "feather",
        icon: "icon-x-circle",
      });
    },
    putData() {
      console.log("TES PARAM EDIT",this.paramData())
      this.$http
        .put(
          "/api/oms/v1/sfa-master/customer-address-sfa/" + this.customerAddressId,
          this.paramData()
        )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            if (this.statusApproval == '4'){
              this.generateApproval()
            }else {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Customer Adress Updated",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          } else {
            this.notify("danger", "Error", resp.message)
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.customerAddressId) {
        this.$http
          .get("/api/oms/v1/sfa-master/customer-address/" + this.customerAddressId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              console.log("RESP",resp)
              // this.$http
              // .get("/api/oms/v1/sfa-master/customer/" + resp.data.customer_id)
              // .then((resp1) => {
              //   this.$vs.loading.close();
              //   if (resp1.code == 200) {
              //     this.data.customerCode = resp1.data.code;
              //     this.data.customerCodeExternal = resp1.data.code_external;
              //     this.data.customerName = resp1.data.name;
              //   } else {
              //     this.notify("danger", "Error", resp.message)
              //   }
              // })
              // .catch((error) => {
              //   this.$vs.loading.close();
              //   console.log(error);
              // });

              this.data.customerCode = resp.data.customer.code;
              this.data.customerCodeExternal = resp.data.customer.code_external;
              this.data.customerName = resp.data.customer.name;
              this.data.customerId = resp.data.customer_id;
              this.data.code = resp.data.code;
              this.data.nooCode = resp.data.code_noo;
              this.territory.selected = resp.data.territory;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;
              this.data.address.countryID = resp.data.country_id;
              this.data.address.provinceID = resp.data.province_id;
              this.data.address.cityID = resp.data.city_id;
              this.data.address.districtID = resp.data.district_id;
              this.data.address.subdistrictID = resp.data.sub_district_id;
              this.salesPersonal.selected = resp.data.sales;
              this.data.rtRw = resp.data.rt_rw;
              this.data.street = resp.data.street;
              this.data.streetNo = resp.data.street_no;
              if (resp.data.is_tax_collector == "1") {
                this.data.isTaxCollector = true;
              } else {
                this.data.isTaxCollector = false;
              }
              if (resp.data.is_tax_exempt == "1") {
                this.data.isTaxExempt = true;
              } else {
                this.data.isTaxExempt = false;
              }

              if (resp.data.status) {
                this.data.status = JSON.parse(resp.data.status);
              }

              this.data.map.address = resp.data.map_address;
              this.data.map.latitude = resp.data.latitude;
              this.data.map.longitude = resp.data.longitude;

              this.data.contact.name = resp.data.contact_name;
              this.data.contact.mobile = resp.data.contact_mobile;
              this.data.contact.phone = resp.data.phone;
              this.data.contact.email = resp.data.email;
              this.data.contact.fax = resp.data.fax;

              if (resp.data.sales_personal_id) {
                this.salesPersonal.selected = {id: resp.data.sales_personal_id}
                this.setDataSales(resp.data.sales_personal_id)
              }
              if (resp.data.tax_type_id) {
                this.setTaxTypeSelected(resp.data.tax_type_id)
              }
              this.data.historyNotes = resp.data.history_note
              this.StatusBlock = this.StatusBlockOption.find(
                option => option.id === resp.data.shiping_block
              );
            } else {
              this.notify("danger", "Error", resp.message)
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        if (this.customerId) {
          this.$http
            .get("/api/oms/v1/sfa-master/customer/" + this.customerId)
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.data.address.address = resp.data.address;
                this.data.address.postalCode = resp.data.postal_code;
                this.data.address.countryName = resp.data.country;
                this.data.address.provinceName = resp.data.province;
                this.data.address.cityName = resp.data.city;
                this.data.address.districtName = resp.data.district;
                this.data.address.subdistrictName = resp.data.sub_district;
                // this.data.map.address = resp.data.map_address;
                // this.data.map.latitude = resp.data.latitude;
                // this.data.map.longitude = resp.data.longitude
              } else {
                this.notify("danger", "Error", resp.message)
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      }
    },
    getDataTerritories() {
      console.log("get data territory")
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/setting-user/territory-master", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataCustomer(query) {
      this.$vs.loading();
      let params = {
        length: 10,
        search: query,
        status: "Active"
      };
      this.$http
        .get("/api/oms/v1/sfa-master/customer-simple", {params: params})
        .then((resp) => {
          if (resp.status == "success") {
            this.data.customer.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataTerritory(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/setting-user/territory/" + id).then((resp) => {
        if (resp.status == "success") {
          this.territory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getDataSales(territoryID) {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/customer-sales/territory/" + territoryID)
        .then((resp) => {
          if (resp.status == "success") {
            this.salesPersonal.options = resp.data;
            if (this.salesPersonal.selected != null) {
              let obj = resp.data.find(o => o.ID === this.salesPersonal.selected.id);
              this.salesPersonal.selected = obj
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setDataSales(selectedSalesPersonalID) {
      if (this.salesPersonal.options.length > 0) {
        let obj = this.salesPersonal.options.find(o => o.ID === selectedSalesPersonalID || o.id === selectedSalesPersonalID);
        this.salesPersonal.selected = obj
      }
    },
    setDataContact(val) {
      this.data.contact = val;
    },
    setDataMap(val) {
      this.data.map = val;
    },
    setDataAddress(val) {
      this.data.address = val;
    },
    getDataTaxType() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/tax-type", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.taxType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setTaxTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/tax-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.taxType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
  mounted() {
    this.getData();
    this.getDataTerritories();
    this.getDataTaxType();

    if (this.customerId == 0 | isNaN(this.customerId)) {
      console.log("get customer from mounted")
      this.getDataCustomer()
    }
  },
  computed: {},
  watch: {
    customerAddressId(v) {
      if (v) {
        this.getData();
      } else {
        this.getDataCustomer()
        Object.assign(this.$data, this.initialState());
      }
    },
    "territory.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        // let id = val.id ? val.id : val.ID
        this.getDataSales(val.id ? val.id : val.ID)
      }
    },
    "taxType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
      }
    },
    "StatusBlock": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
      }
    },
    "data.customer.selected": function (val) {
      this.data.customer.selected = val;
      if (val) {
        console.log(val)
        this.data.customerCode = val.code;
        this.data.customerName = val.name;
        this.data.customerId = val.ID ? val.ID : val.id;
      } else {
        this.data.code = null;
        this.data.customerName = null
        this.data.customerId = null
      }
    },
    "data.rtRw": function (val) {
      if (val.length == 8) {
        this.data.rtRw = this.data.rtRw.slice(0, -1)
      } 
      if (val.length < 7 || val.indexOf("/") != 3 || (val.split("/").length - 1) > 1) {
        this.errors.add({
          field: "RT/RW",
          msg: "The RT / RW not valid format.",
        });
      } else if (val.length == 7) {
        this.errors.clear();
      }
    },
  },
};
</script>
<style >
.readonly > div > input {
  background-color: #ebebeb;
}
</style>
